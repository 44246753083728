<script setup lang="ts">
useHead({
  title: 'Login',
})
definePageMeta({
  layout: 'login'
})
const successMessage = ref('');
const loginField = ref('');
const password = ref('');
const windowPage = ref(1);

const resetEmail = ref('')
const loading = ref(false);
const loginError = ref('');
const resetError = ref('');

const loginRules = ref([
  (v: any) => !!v || 'Email or Username is required',
]);

const emailRules = ref([
  (v: any) => !!v || 'Email is required',
  (v: any) => /.+@.+\..+/.test(v) || 'Email must be valid',
]);

const snackbar = ref({
  show: false,
  text: '',
  color: '',
  timeout: 3000
});

const showSnackbar = (message: string, color: string) => {
  snackbar.value.text = message;
  snackbar.value.color = color;
  snackbar.value.show = true;
};

const body = computed(() => (JSON.stringify({
  email: loginField.value.includes('@') ? loginField.value : undefined,
  username: !loginField.value.includes('@') ? loginField.value : undefined,
  password: password.value
})));
const user = useState<any>('user');
const token = useCookie<string>('token')

onBeforeMount(async () => {
  // do a quick check on user state
  try {
    await $fetch('/api/v1/admin/account/me', {
      headers: {
        'Authorization': `Bearer ${token.value}`
      }
    });
    navigateTo('/');
  } catch {
    user.value = null; // clear user state
    token.value = '';
  }
})

async function onSubmit() {
  try {
    const response: any = await $fetch('/api/v1/admin/auth/login', {
      method: 'POST',
      body: body.value,
    });
    useState('user', () => response)
    token.value = response.token
    navigateTo('/');
  } catch (error: any) {
    loginError.value = error.statusCode === 401 ? 'Invalid email or password' : 'Failed to login, please try again';
    showSnackbar(loginError.value, 'error');
  }
}

async function onReset() {
  try {
    loading.value = true;
    await $fetch('/api/v1/admin/auth/reset_password', {
      method: 'POST',
      body: { email: resetEmail.value },
    });
    successMessage.value = 'An email has been sent to reset your password.';
    showSnackbar(successMessage.value, 'success');
    resetError.value = '';

    setTimeout(() => {
      windowPage.value = 1;
      successMessage.value = '';
    }, 5000);

  } catch (error: any) {
    resetError.value = error.statusCode === 404 ? 'User not found' : 'Failed to reset password';
    showSnackbar(resetError.value, 'error');
    successMessage.value = '';
  } finally {
    loading.value = false;
  }
}

</script>

<template>
  <v-app>
    <v-main>
      <v-container class="loginP">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" class="d-flex flex-column align-center">
            <v-img :width="300" src="/prismatic-logo.svg" class="mb-2 align-center sbi-logo" contain></v-img>
            <h1 class="mb-2 align-center">Admin Panel</h1>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="mx-auto">
              <v-window v-model="windowPage">
                <v-window-item :value="1">
                  <v-form class="pa-5" @keyup.enter="onSubmit">
                    <p class="label">Email or Username</p>
                    <v-text-field v-model="loginField" :rules="loginRules" required variant="outlined"></v-text-field>
                    <p class="label">Password</p>
                    <v-text-field v-model="password" required type="password" variant="outlined"></v-text-field>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <span @click="windowPage = 2" style="text-decoration: underline;color: #808B95;cursor: pointer;"
                        class="pr-5">Forgot password?</span>
                      <v-btn @click="onSubmit" class="login" variant="flat">
                        Login
                      </v-btn>
                    </v-card-actions>
                    <v-alert v-if="loginError" class="mt-2" type="error" color="error">{{ loginError }}</v-alert>
                  </v-form>
                </v-window-item>
                <v-window-item :value="2">
                  <v-form class="pa-5" @submit.prevent="onReset">
                    <v-card-title>Reset Password</v-card-title>
                    <v-card-text>
                      <v-text-field v-model="resetEmail" :rules="emailRules" color="primary" label="Email"
                        variant="outlined"></v-text-field>

                      <p class="note">**Do not forget to check SPAM box.</p>
                      <v-alert v-if="successMessage" class="mt-2" type="success" color="success">{{ successMessage
                        }}</v-alert>
                      <v-alert v-if="resetError" class="mt-2" type="error" color="error">{{ resetError }}</v-alert>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <span @click="windowPage = 1" style="color: #808B95;cursor: pointer;" class="pr-5">
                        <v-icon icon="mdi-chevron-left"></v-icon>Back
                      </span>
                      <v-btn type="submit" :loading="loading">
                        <template v-if="!loading">
                          Continue
                        </template>
                        <template v-else>
                          <v-progress-circular indeterminate size="24"></v-progress-circular>
                        </template>
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
        <Snackbar :show="snackbar.show" :text="snackbar.text" :color="snackbar.color" :timeout="snackbar.timeout"
          @update:show="snackbar.show = $event" />
      </v-container>
    </v-main>
  </v-app>
</template>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
.loginP {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
}

.logo {
  font-family: 'Noto Sans';
  font-weight: 700;
  font-size: 1rem;
}

h1 {
  font-size: 30px;
  font-family: 'Nunito Sans';
  color: #231F20;
}

.label {
  color: #253779;
  font-family: 'Noto Sans';
  font-weight: 700;
}

.v-btn,
.login {
  background-color: #253779;
  color: white;
}

.sbi-logo {
  padding-bottom: 50px;
}

.note {
  color: red;
  font-size: 14px;
}
</style>